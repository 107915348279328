<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Editors component
 */
export default {
  page: {
    title: "Create Notification",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      valid: true,
      submitted: false,
      title: "Create Notification",
      items: [
        {
          text: "Create Notification",
          href: "#",
        },
        {
          text: "Notification",
          active: true,
        },
      ],
      form: {
        type: "",
        content: "",
        users: "",
        date: "",
      },
      slctTemp: null,
      slctTempOptions: [
        { key: 0, value: "Single" },
        { key: 1, value: "All" },
      ],

      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons Notification paste textcolor imagetools",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      allData: [],
    };
  },
  mounted() {
    this.getData();
  },
  validations: {
    form: {
      type: {
        required,
      },
      content: {
        required,
      },
      date: {
        required,
      },
    },
  },
  methods: {
    async getData() {
      await ApiClass.getRequest("admin/fund/get", true).then((res) => {
        if (res.data.status_code == 1) {
          this.allData = res.data.data.users;
        }
      });
    },

    async save() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      var obj = {
        type: this.form.type.value.toLowerCase(),
        user_id: this.form.users.id,
        content: this.form.content,
        expired_at: this.form.date,
      };

      // console.log(obj);
      var result = await ApiClass.postRequest(
        "admin/notification/create",
        true,
        obj
      );
      // console.log(result);
      this.submitted = false;
      if (result.data.status_code == "1") {
        this.form.type = {};
        this.form.content = this.form.date = "";

        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    onReset(event) {
      event.preventDefault();
      this.submitted = false;
      this.form.type = {};
      this.form.date = this.form.content = "";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="save" @reset="onReset">
            <!-- Text Editor -->

            <div class="row">
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.form.content.$error }"
              >
                <p class="card-title-desc">Add Content :</p>
                <tinymce
                  id="d1"
                  v-model.trim="$v.form.content.$model"
                  :plugins="plugins"
                  :toolbar="toolbar"
                  :other_options="options"
                  required
                ></tinymce>
                <div
                  class="error"
                  v-if="submitted && !$v.form.content.required"
                >
                  Content is required
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.form.type.$error }"
                >
                  <p class="card-title-desc mt-2">Assign To:</p>
                  <multiselect
                    v-model.trim="$v.form.type.$model"
                    track-by="key"
                    label="value"
                    :options="slctTempOptions"
                    required
                  ></multiselect>
                  <div class="error" v-if="submitted && !$v.form.type.required">
                    Type is required
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.form.date.$error }"
                >
                  <p class="card-title-desc mt-2">Expired At</p>
                  <div class="input-group date" id="datepicker">
                    <input
                      type="date"
                      class="form-control"
                      id="date"
                      v-model="form.date"
                    />
                  </div>
                  <div class="error" v-if="submitted && !$v.form.date.required">
                    Date is required
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div v-if="form.type.key == 0">
                  <p class="card-title-desc mt-2">User List</p>
                  <multiselect
                    v-model.trim="form.users"
                    track-by="id"
                    label="name"
                    :options="allData"
                  ></multiselect>
                </div>
              </div>
            </div>
            <b-button class="mt-3" type="submit" variant="primary"
              >Submit</b-button
            >
            <b-button class="mt-3" type="reset" variant="danger"
              >Reset</b-button
            >
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>